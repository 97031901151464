// roles article
export const rolesArticleList = (locale) => [
  {
    href: "/special/page34",
    image: `/static/img/tool/headerImage/page34/${locale}.png`,
    title: "special.p85",
  },
  // {
  //   href: "/special/page33",
  //   image: `/static/img/tool/headerImage/page33/${locale}.png`,
  //   title: "special.p83",
  // },
  {
    href: "/special/page32",
    image: `/static/img/tool/headerImage/page32/${locale}.png`,
    title: "special.p81",
  },
  {
    href: "/special/page31",
    image: `/static/img/tool/headerImage/page31/${locale}.png`,
    title: "special.p79",
  },
  {
    href: "/special/page30",
    image: `/static/img/tool/headerImage/page30/${locale}.png`,
    title: "special.p77",
  },
  /*   {
        href: '/special/page29',
        image: `/static/img/tool/headerImage/page28/${locale}.png`,
        title: 'special.p75',
    },
    {
        href: '/special/page28',
        image: `/static/img/tool/headerImage/page28/${locale}.png`,
        title: 'special.p73',
    }, */
  {
    href: "/special/page27",
    image: `/static/img/tool/headerImage/page27/${locale}.png`,
    title: "special.p71",
  },
  {
    href: "/special/page26",
    image: `/static/img/tool/headerImage/page26/${locale}.png`,
    title: "special.p69",
  },
  {
    href: "/special/page25",
    image: `/static/img/tool/headerImage/page25/${locale}.png`,
    title: "special.p67",
  },
  {
    href: "/special/page24",
    image: `/static/img/tool/headerImage/page24/${locale}.png`,
    title: "special.p65",
  },
  {
    href: "/special/page23",
    image: `/static/img/tool/headerImage/page23/${locale}.jpg`,
    title: "special.p63",
  },
  {
    href: "/special/page22",
    image: `/static/img/tool/headerImage/page22/${locale}.jpg`,
    title: "special.p61",
  },
  {
    href: "/special/page21",
    image: `/static/img/tool/headerImage/page21/${locale}.jpg`,
    title: "special.p59",
  },
  {
    href: "/special/page20",
    image: `/static/img/tool/headerImage/page20/${locale}.jpg`,
    title: "special.p57",
  },
  {
    href: "/special/page19",
    image: `/static/img/tool/headerImage/page19/${locale}.jpg`,
    title: "special.p54",
  },
  {
    href: "/special/page18",
    image: `/static/img/tool/headerImage/page18/${locale}.jpg`,
    title: "special.p53",
  },
  {
    href: "/special/page17",
    image: `/static/img/tool/headerImage/page17/${locale}.jpg`,
    title: "special.p51",
  },
  {
    href: "/special/page16",
    image: `/static/img/tool/headerImage/page16/${locale}.jpg`,
    title: "special.p49",
  },
  {
    href: "/special/page15",
    image: `/static/img/tool/headerImage/page15/${locale}.jpg`,
    title: "special.p47",
  },
  {
    href: "/special/page13",
    image: `/static/img/tool/headerImage/page13/${locale}.jpg`,
    title: "special.p43",
  },
  {
    href: "/special/page14",
    image: `/static/img/tool/headerImage/page14/${locale}.jpg`,
    title: "special.p45",
  },
  {
    href: "/special/page11",
    image: "/static/img/tool/headerImagePage11-1.png",
    title: "special.p33",
  },
  {
    href: "/special/page12",
    image: "/static/img/tool/headerImagePage12-1.png",
    title: "special.p35",
  },
  {
    href: "/special/page09",
    image: "/static/img/tool/headerImagePage09-1.jpg",
    title: "special.p31",
  },
  {
    href: "/special/page10",
    image: "/static/img/tool/headerImagePage10-1.jpg",
    title: "special.p29",
  },
  {
    href: "/special/page07",
    image: "/static/img/tool/headerImagePage07-1.jpg",
    title: "special.p3",
  },
  {
    href: "/special/page08",
    image: "/static/img/tool/headerImagePage08-1.jpg",
    title: "special.p5",
  },
  {
    href: "/special/page04",
    image: "/static/img/tool/headerImagePage04-1.jpg",
    title: "special.p7",
  },
  {
    href: "/special/page05",
    image: "/static/img/tool/headerImagePage05-1.jpg",
    title: "special.p9",
  },
  {
    href: "/special/page06",
    image: "/static/img/tool/headerImagePage06-1.jpg",
    title: "special.p11",
  },
  {
    href: "/special/page01",
    image: "/static/img/tool/headerImagePage01-1.jpg",
    title: "special.p13",
  },
  {
    href: "/special/page02",
    image: "/static/img/tool/headerImagePage02-1.jpg",
    title: "special.p15",
  },
  {
    href: "/special/page03",
    image: "/static/img/tool/headerImagePage03-1.png",
    title: "special.p17",
  },
];
export const gender = {
  male: "male",
  female: "female",
};
// roles special detail page
export const specialDetailPage = (locale) => [
  {
    code: "page01",
    image: "/static/img/tool/headerImagePage01-1.jpg",
    title: "special.p13",
    subtitle: "2021.12.10",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p14",
      href: "/static/img/tool/zip/emote_seibi.zip",
      downloadName: "emote_seibi",
      gifFile: [
        {
          href: "/static/img/tool/download/role01/angry.gif",
          name: "angry",
        },
        {
          href: "/static/img/tool/download/role01/champion.gif",
          name: "champion",
        },
        {
          href: "/static/img/tool/download/role01/eating.gif",
          name: "eating",
        },
        {
          href: "/static/img/tool/download/role01/love.gif",
          name: "love",
        },
        {
          href: "/static/img/tool/download/role01/sad.gif",
          name: "sad",
        },
        {
          href: "/static/img/tool/download/role01/sleep.gif",
          name: "sleep",
        },
      ],
    },
  },
  {
    code: "page02",
    image: "/static/img/tool/headerImagePage02-1.jpg",
    title: "special.p15",
    subtitle: "2021.12.10",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p16",
      href: "/static/img/tool/zip/emote_kanna.zip",
      downloadName: "emote_kanna",
      gifFile: [
        {
          href: "/static/img/tool/download/role02/angry.gif",
          name: "angry",
        },
        {
          href: "/static/img/tool/download/role02/beat.gif",
          name: "beat",
        },
        {
          href: "/static/img/tool/download/role02/crying.gif",
          name: "crying",
        },
        {
          href: "/static/img/tool/download/role02/good.gif",
          name: "good",
        },
        {
          href: "/static/img/tool/download/role02/peace.gif",
          name: "peace",
        },
        {
          href: "/static/img/tool/download/role02/wave.gif",
          name: "wave",
        },
      ],
    },
  },
  {
    code: "page03",
    image: "/static/img/tool/headerImagePage03-1.png",
    title: "special.p17",
    subtitle: "2021.12.10",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p18",
      href: "/static/img/tool/zip/emote_nagi.zip",
      downloadName: "emote_nagi",
      gifFile: [
        {
          href: "/static/img/tool/download/role03/entrence.gif",
          name: "entrence",
        },
        {
          href: "/static/img/tool/download/role03/grateful.gif",
          name: "grateful",
        },
        {
          href: "/static/img/tool/download/role03/insidious.gif",
          name: "insidious",
        },
        {
          href: "/static/img/tool/download/role03/press.gif",
          name: "press",
        },
        {
          href: "/static/img/tool/download/role03/resignedly.gif",
          name: "resignedly",
        },
        {
          href: "/static/img/tool/download/role03/scratches.gif",
          name: "scratches",
        },
      ],
    },
  },
  {
    code: "page04",
    image: "/static/img/tool/headerImagePage04-1.jpg",
    title: "special.p7",
    subtitle: "2021.12.21",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p8",
      href: "/static/img/tool/zip/emote_sako.zip",
      downloadName: "emote_sako",
      gifFile: [
        {
          href: "/static/img/tool/download/role04/4-1.gif",
          name: "4-1",
        },
        {
          href: "/static/img/tool/download/role04/4-2.gif",
          name: "4-2",
        },
        {
          href: "/static/img/tool/download/role04/4-3.gif",
          name: "4-3",
        },
        {
          href: "/static/img/tool/download/role04/4-4.gif",
          name: "4-4",
        },
        {
          href: "/static/img/tool/download/role04/4-5.gif",
          name: "4-5",
        },
        {
          href: "/static/img/tool/download/role04/4-6.gif",
          name: "4-6",
        },
      ],
    },
  },
  {
    code: "page05",
    image: "/static/img/tool/headerImagePage05-1.jpg",
    title: "special.p9",
    subtitle: "2021.12.21",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p10",
      href: "/static/img/tool/zip/emote_shiotsuki.zip",
      downloadName: "emote_shiotsuki",
      gifFile: [
        {
          href: "/static/img/tool/download/role05/5-1.gif",
          name: "5-1",
        },
        {
          href: "/static/img/tool/download/role05/5-2.gif",
          name: "5-2",
        },
        {
          href: "/static/img/tool/download/role05/5-3.gif",
          name: "5-3",
        },
        {
          href: "/static/img/tool/download/role05/5-4.gif",
          name: "5-4",
        },
        {
          href: "/static/img/tool/download/role05/5-5.gif",
          name: "5-5",
        },
        {
          href: "/static/img/tool/download/role05/5-6.gif",
          name: "5-6",
        },
      ],
    },
  },
  {
    code: "page06",
    image: "/static/img/tool/headerImagePage06-1.jpg",
    title: "special.p11",
    subtitle: "2021.12.21",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p12",
      href: "/static/img/tool/zip/emote_renshin.zip",
      downloadName: "emote_renshin",
      gifFile: [
        {
          href: "/static/img/tool/download/role06/6-1.gif",
          name: "6-1",
        },
        {
          href: "/static/img/tool/download/role06/6-2.gif",
          name: "6-2",
        },
        {
          href: "/static/img/tool/download/role06/6-3.gif",
          name: "6-3",
        },
        {
          href: "/static/img/tool/download/role06/6-4.gif",
          name: "6-4",
        },
        {
          href: "/static/img/tool/download/role06/6-5.gif",
          name: "6-5",
        },
        {
          href: "/static/img/tool/download/role06/6-6.gif",
          name: "6-6",
        },
      ],
    },
  },
  {
    code: "page07",
    image: "/static/img/tool/headerImagePage07-1.jpg",
    title: "special.p3",
    subtitle: "2021.12.21",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.male,
    middlePage: {
      roleName: "special.p4",
      href: "/static/img/tool/zip/emote_kenichi.zip",
      downloadName: "emote_kenichi",
      gifFile: [
        {
          href: "/static/img/tool/download/role07/7-1.gif",
          name: "7-1",
        },
        {
          href: "/static/img/tool/download/role07/7-2.gif",
          name: "7-2",
        },
        {
          href: "/static/img/tool/download/role07/7-3.gif",
          name: "7-3",
        },
        {
          href: "/static/img/tool/download/role07/7-4.gif",
          name: "7-4",
        },
        {
          href: "/static/img/tool/download/role07/7-5.gif",
          name: "7-5",
        },
        {
          href: "/static/img/tool/download/role07/7-6.gif",
          name: "7-6",
        },
      ],
    },
  },
  {
    code: "page08",
    image: "/static/img/tool/headerImagePage08-1.jpg",
    title: "special.p5",
    subtitle: "2021.12.21",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.male,
    middlePage: {
      roleName: "special.p6",
      href: "/static/img/tool/zip/emote_janmaru.zip",
      downloadName: "emote_janmaru",
      gifFile: [
        {
          href: "/static/img/tool/download/role08/8-1.gif",
          name: "8-1",
        },
        {
          href: "/static/img/tool/download/role08/8-2.gif",
          name: "8-2",
        },
        {
          href: "/static/img/tool/download/role08/8-3.gif",
          name: "8-3",
        },
        {
          href: "/static/img/tool/download/role08/8-4.gif",
          name: "8-4",
        },
        {
          href: "/static/img/tool/download/role08/8-5.gif",
          name: "8-5",
        },
        {
          href: "/static/img/tool/download/role08/8-6.gif",
          name: "8-6",
        },
      ],
    },
  },
  {
    code: "page09",
    image: "/static/img/tool/headerImagePage09-1.jpg",
    title: "special.p31",
    subtitle: "2022.07.06",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p32",
      href: "/static/img/tool/zip/Saotome_Yurika.zip",
      downloadName: "Saotome_Yurika",
      gifFile: [
        {
          href: "/static/img/tool/download/role09/9-1.gif",
          name: "9-1",
        },
        {
          href: "/static/img/tool/download/role09/9-2.gif",
          name: "9-2",
        },
        {
          href: "/static/img/tool/download/role09/9-3.gif",
          name: "9-3",
        },
        {
          href: "/static/img/tool/download/role09/9-4.gif",
          name: "9-4",
        },
        {
          href: "/static/img/tool/download/role09/9-5.gif",
          name: "9-5",
        },
        {
          href: "/static/img/tool/download/role09/9-6.gif",
          name: "9-6",
        },
      ],
    },
  },
  {
    code: "page10",
    image: "/static/img/tool/headerImagePage10-1.jpg",
    title: "special.p29",
    subtitle: "2022.07.06",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p30",
      href: "/static/img/tool/zip/Akabane_Athena.zip",
      downloadName: "Akabane_Athena",
      gifFile: [
        {
          href: "/static/img/tool/download/role10/10-1.gif",
          name: "10-1",
        },
        {
          href: "/static/img/tool/download/role10/10-2.gif",
          name: "10-2",
        },
        {
          href: "/static/img/tool/download/role10/10-3.gif",
          name: "10-3",
        },
        {
          href: "/static/img/tool/download/role10/10-4.gif",
          name: "10-4",
        },
        {
          href: "/static/img/tool/download/role10/10-5.gif",
          name: "10-5",
        },
        {
          href: "/static/img/tool/download/role10/10-6.gif",
          name: "10-6",
        },
      ],
    },
  },
  {
    code: "page11",
    image: "/static/img/tool/headerImagePage11-1.png",
    title: "special.p33",
    subtitle: "2022.07.19",
    topTag: "special.p2",
    pageName: "SpecialPage11",
    middlePage: {
      href: "/static/img/tool/zip/icon.zip",
      downloadName: "icon",
      gifFile: [
        {
          href: "/static/img/tool/download/role11/normal_1.png",
          name: "11-1",
        },
        {
          href: "/static/img/tool/download/role11/normal_2.png",
          name: "11-2",
        },
        {
          href: "/static/img/tool/download/role11/normal_3.png",
          name: "11-3",
        },
        {
          href: "/static/img/tool/download/role11/normal_4.png",
          name: "11-4",
        },
        {
          href: "/static/img/tool/download/role11/normal_5.png",
          name: "11-5",
        },
        {
          href: "/static/img/tool/download/role11/normal_6.png",
          name: "11-6",
        },
        {
          href: "/static/img/tool/download/role11/normal_7.png",
          name: "11-7",
        },
        {
          href: "/static/img/tool/download/role11/normal_8.png",
          name: "11-8",
        },
        {
          href: "/static/img/tool/download/role11/normal_9.png",
          name: "11-9",
        },
        {
          href: "/static/img/tool/download/role11/normal_10.png",
          name: "11-10",
        },
        {
          href: "/static/img/tool/download/role11/awaken_1.png",
          name: "11-11",
        },
        {
          href: "/static/img/tool/download/role11/awaken_2.png",
          name: "11-12",
        },
        {
          href: "/static/img/tool/download/role11/awaken_3.png",
          name: "11-13",
        },
        {
          href: "/static/img/tool/download/role11/awaken_4.png",
          name: "11-14",
        },
        {
          href: "/static/img/tool/download/role11/awaken_5.png",
          name: "11-15",
        },
        {
          href: "/static/img/tool/download/role11/awaken_6.png",
          name: "11-16",
        },
        {
          href: "/static/img/tool/download/role11/awaken_7.png",
          name: "11-17",
        },
        {
          href: "/static/img/tool/download/role11/awaken_8.png",
          name: "11-18",
        },
        {
          href: "/static/img/tool/download/role11/awaken_9.png",
          name: "11-19",
        },
        {
          href: "/static/img/tool/download/role11/awaken_10.png",
          name: "11-20",
        },
      ],
    },
  },
  {
    code: "page12",
    image: "/static/img/tool/headerImagePage12-1.png",
    title: "special.p35",
    subtitle: "2022.07.19",
    topTag: "special.p2",
    pageName: "SpecialPage12",
    middlePage: {
      href: "/static/img/tool/zip/header.zip",
      downloadName: "header",
      gifFile: [
        {
          href: "/static/img/tool/download/role12/header1.jpg",
          name: "12-1",
        },
        {
          href: "/static/img/tool/download/role12/header2.jpg",
          name: "12-2",
        },
        {
          href: "/static/img/tool/download/role12/header3.jpg",
          name: "12-3",
        },
        {
          href: "/static/img/tool/download/role12/header4.jpg",
          name: "12-4",
        },
        {
          href: "/static/img/tool/download/role12/header5.jpg",
          name: "12-5",
        },
      ],
    },
  },
  {
    code: "page13",
    image: `/static/img/tool/headerImage/page13/${locale}.jpg`,
    title: "special.p43",
    subtitle: "2022.09.29",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p44",
      href: "/static/img/tool/zip/Marin.zip",
      downloadName: "Natsuumi_Marin",
      gifFile: [
        {
          href: "/static/img/tool/download/role13/13-01.gif",
          name: "13-01",
        },
        {
          href: "/static/img/tool/download/role13/13-02.gif",
          name: "13-02",
        },
        {
          href: "/static/img/tool/download/role13/13-03.gif",
          name: "13-03",
        },
        {
          href: "/static/img/tool/download/role13/13-04.gif",
          name: "13-04",
        },
        {
          href: "/static/img/tool/download/role13/13-05.gif",
          name: "13-05",
        },
        {
          href: "/static/img/tool/download/role13/13-06.gif",
          name: "13-06",
        },
        {
          href: "/static/img/tool/download/role13/13-07.gif",
          name: "13-07",
        },
        {
          href: "/static/img/tool/download/role13/13-08.gif",
          name: "13-08",
        },
      ],
    },
  },
  {
    code: "page14",
    image: `/static/img/tool/headerImage/page14/${locale}.jpg`,
    title: "special.p45",
    subtitle: "2022.09.29",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.male,
    middlePage: {
      roleName: "special.p46",
      href: "/static/img/tool/zip/Naoya.zip",
      downloadName: "Saotome_Naoya",
      gifFile: [
        {
          href: "/static/img/tool/download/role14/14-1.gif",
          name: "14-1",
        },
        {
          href: "/static/img/tool/download/role14/14-2.gif",
          name: "14-2",
        },
        {
          href: "/static/img/tool/download/role14/14-3.gif",
          name: "14-3",
        },
        {
          href: "/static/img/tool/download/role14/14-4.gif",
          name: "14-4",
        },
        {
          href: "/static/img/tool/download/role14/14-5.gif",
          name: "14-5",
        },
        {
          href: "/static/img/tool/download/role14/14-6.gif",
          name: "14-6",
        },
      ],
    },
  },
  {
    code: "page15",
    image: `/static/img/tool/headerImage/page15/${locale}.jpg`,
    title: "special.p47",
    subtitle: "2023.02.24",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p48",
      href: "/static/img/tool/zip/Alice.zip",
      downloadName: "Alice",
      gifFile: [
        {
          href: "/static/img/tool/download/role15/15-1.gif",
          name: "15-1",
        },
        {
          href: "/static/img/tool/download/role15/15-2.gif",
          name: "15-2",
        },
        {
          href: "/static/img/tool/download/role15/15-3.gif",
          name: "15-3",
        },
        {
          href: "/static/img/tool/download/role15/15-4.gif",
          name: "15-4",
        },
        {
          href: "/static/img/tool/download/role15/15-5.gif",
          name: "15-5",
        },
        {
          href: "/static/img/tool/download/role15/15-6.gif",
          name: "15-6",
        },
        {
          href: "/static/img/tool/download/role15/15-7.gif",
          name: "15-7",
        },
        {
          href: "/static/img/tool/download/role15/15-8.gif",
          name: "15-8",
        },
      ],
    },
  },
  {
    code: "page16",
    image: `/static/img/tool/headerImage/page16/${locale}.jpg`,
    title: "special.p49",
    subtitle: "2023.02.24",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p50",
      href: "/static/img/tool/zip/Menia.zip",
      downloadName: "Menia",
      gifFile: [
        {
          href: "/static/img/tool/download/role16/16-1.gif",
          name: "16-1",
        },
        {
          href: "/static/img/tool/download/role16/16-2.gif",
          name: "16-2",
        },
        {
          href: "/static/img/tool/download/role16/16-3.gif",
          name: "16-3",
        },
        {
          href: "/static/img/tool/download/role16/16-4.gif",
          name: "16-4",
        },
        {
          href: "/static/img/tool/download/role16/16-5.gif",
          name: "16-5",
        },
        {
          href: "/static/img/tool/download/role16/16-6.gif",
          name: "16-6",
        },
        {
          href: "/static/img/tool/download/role16/16-7.gif",
          name: "16-7",
        },
        {
          href: "/static/img/tool/download/role16/16-8.gif",
          name: "16-8",
        },
      ],
    },
  },
  {
    code: "page17",
    image: `/static/img/tool/headerImage/page17/${locale}.jpg`,
    title: "special.p51",
    subtitle: "2023.02.24",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p52",
      href: "/static/img/tool/zip/Aurora.zip",
      downloadName: "Aurora",
      gifFile: [
        {
          href: "/static/img/tool/download/role17/17-1.gif",
          name: "17-1",
        },
        {
          href: "/static/img/tool/download/role17/17-2.gif",
          name: "17-2",
        },
        {
          href: "/static/img/tool/download/role17/17-3.gif",
          name: "17-3",
        },
        {
          href: "/static/img/tool/download/role17/17-4.gif",
          name: "17-4",
        },
        {
          href: "/static/img/tool/download/role17/17-5.gif",
          name: "17-5",
        },
        {
          href: "/static/img/tool/download/role17/17-6.gif",
          name: "17-6",
        },
        {
          href: "/static/img/tool/download/role17/17-7.gif",
          name: "17-7",
        },
        {
          href: "/static/img/tool/download/role17/17-8.gif",
          name: "17-8",
        },
      ],
    },
  },
  {
    code: "page18",
    image: `/static/img/tool/headerImage/page18/${locale}.jpg`,
    title: "special.p53",
    subtitle: "2023.03.22",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p55",
      href: "/static/img/tool/zip/Asuka.zip",
      downloadName: "Asuka",
      gifFile: [
        {
          href: "/static/img/tool/download/role18/18-7.gif",
          name: "18-7",
        },
        {
          href: "/static/img/tool/download/role18/18-2.gif",
          name: "18-2",
        },
        {
          href: "/static/img/tool/download/role18/18-3.gif",
          name: "18-3",
        },
        {
          href: "/static/img/tool/download/role18/18-4.gif",
          name: "18-4",
        },
        {
          href: "/static/img/tool/download/role18/18-5.gif",
          name: "18-5",
        },
        {
          href: "/static/img/tool/download/role18/18-6.gif",
          name: "18-6",
        },
        {
          href: "/static/img/tool/download/role18/18-1.gif",
          name: "18-1",
        },
        {
          href: "/static/img/tool/download/role18/18-8.gif",
          name: "18-8",
        },
      ],
    },
  },
  {
    code: "page19",
    image: `/static/img/tool/headerImage/page19/${locale}.jpg`,
    title: "special.p54",
    subtitle: "2023.03.22",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.male,
    middlePage: {
      roleName: "special.p56",
      href: "/static/img/tool/zip/Ikki.zip",
      downloadName: "Ikki",
      gifFile: [
        {
          href: "/static/img/tool/download/role19/19-1.gif",
          name: "19-1",
        },
        {
          href: "/static/img/tool/download/role19/19-2.gif",
          name: "19-2",
        },
        {
          href: "/static/img/tool/download/role19/19-3.gif",
          name: "19-3",
        },
        {
          href: "/static/img/tool/download/role19/19-4.gif",
          name: "19-4",
        },
        {
          href: "/static/img/tool/download/role19/19-5.gif",
          name: "19-5",
        },
        {
          href: "/static/img/tool/download/role19/19-6.gif",
          name: "19-6",
        },
        {
          href: "/static/img/tool/download/role19/19-7.gif",
          name: "19-7",
        },
        {
          href: "/static/img/tool/download/role19/19-8.gif",
          name: "19-8",
        },
      ],
    },
  },
  {
    code: "page20",
    image: `/static/img/tool/headerImage/page20/${locale}.jpg`,
    title: "special.p57",
    subtitle: "2023.05.25",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p58",
      href: "/static/img/tool/zip/Michiyuki.zip",
      downloadName: "Michiyuki",
      gifFile: [
        {
          href: "/static/img/tool/download/role20/20-1.gif",
          name: "20-1",
        },
        {
          href: "/static/img/tool/download/role20/20-2.gif",
          name: "20-2",
        },
        {
          href: "/static/img/tool/download/role20/20-3.gif",
          name: "20-3",
        },
        {
          href: "/static/img/tool/download/role20/20-4.gif",
          name: "20-4",
        },
        {
          href: "/static/img/tool/download/role20/20-5.gif",
          name: "20-5",
        },
        {
          href: "/static/img/tool/download/role20/20-6.gif",
          name: "20-6",
        },
        {
          href: "/static/img/tool/download/role20/20-7.gif",
          name: "20-7",
        },
        {
          href: "/static/img/tool/download/role20/20-8.gif",
          name: "20-8",
        },
      ],
    },
  },
  {
    code: "page21",
    image: `/static/img/tool/headerImage/page21/${locale}.jpg`,
    title: "special.p59",
    subtitle: "2023.05.25",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p60",
      href: "/static/img/tool/zip/Ikuko.zip",
      downloadName: "Ikuko",
      gifFile: [
        {
          href: "/static/img/tool/download/role21/21-1.gif",
          name: "21-1",
        },
        {
          href: "/static/img/tool/download/role21/21-2.gif",
          name: "21-2",
        },
        {
          href: "/static/img/tool/download/role21/21-3.gif",
          name: "21-3",
        },
        {
          href: "/static/img/tool/download/role21/21-4.gif",
          name: "21-4",
        },
        {
          href: "/static/img/tool/download/role21/21-5.gif",
          name: "21-5",
        },
        {
          href: "/static/img/tool/download/role21/21-6.gif",
          name: "21-6",
        },
        {
          href: "/static/img/tool/download/role21/21-7.gif",
          name: "21-7",
        },
        {
          href: "/static/img/tool/download/role21/21-8.gif",
          name: "21-8",
        },
      ],
    },
  },
  {
    code: "page22",
    image: `/static/img/tool/headerImage/page22/${locale}.jpg`,
    title: "special.p61",
    subtitle: "2023.07.17",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p62",
      href: "/static/img/tool/zip/Mio.zip",
      downloadName: "Mio",
      gifFile: [
        {
          href: "/static/img/tool/download/role22/22-1.gif",
          name: "22-1",
        },
        {
          href: "/static/img/tool/download/role22/22-2.gif",
          name: "22-2",
        },
        {
          href: "/static/img/tool/download/role22/22-3.gif",
          name: "22-3",
        },
        {
          href: "/static/img/tool/download/role22/22-4.gif",
          name: "22-4",
        },
        {
          href: "/static/img/tool/download/role22/22-5.gif",
          name: "22-5",
        },
        {
          href: "/static/img/tool/download/role22/22-6.gif",
          name: "22-6",
        },
        {
          href: "/static/img/tool/download/role22/22-7.gif",
          name: "22-7",
        },
        {
          href: "/static/img/tool/download/role22/22-8.gif",
          name: "22-8",
        },
      ],
    },
  },
  {
    code: "page23",
    image: `/static/img/tool/headerImage/page23/${locale}.jpg`,
    title: "special.p63",
    subtitle: "2023.09.11",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p64",
      href: "/static/img/tool/zip/kanade.zip",
      downloadName: "kanade",
      gifFile: [
        {
          href: "/static/img/tool/download/role23/23-1.gif",
          name: "23-1",
        },
        {
          href: "/static/img/tool/download/role23/23-2.gif",
          name: "23-2",
        },
        {
          href: "/static/img/tool/download/role23/23-3.gif",
          name: "23-3",
        },
        {
          href: "/static/img/tool/download/role23/23-4.gif",
          name: "23-4",
        },
        {
          href: "/static/img/tool/download/role23/23-5.gif",
          name: "23-5",
        },
        {
          href: "/static/img/tool/download/role23/23-6.gif",
          name: "23-6",
        },
        {
          href: "/static/img/tool/download/role23/23-7.gif",
          name: "23-7",
        },
        {
          href: "/static/img/tool/download/role23/23-8.gif",
          name: "23-8",
        },
      ],
    },
  },
  {
    code: "page24",
    image: `/static/img/tool/headerImage/page24/${locale}.png`,
    title: "special.p65",
    subtitle: "2023.11.03",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p66",
      href: "/static/img/tool/zip/Mahiru.zip",
      downloadName: "Mahiru",
      gifFile: [
        {
          href: "/static/img/tool/download/role24/24-01.gif",
          name: "24-01",
        },
        {
          href: "/static/img/tool/download/role24/24-02.gif",
          name: "24-02",
        },
        {
          href: "/static/img/tool/download/role24/24-03.gif",
          name: "24-03",
        },
        {
          href: "/static/img/tool/download/role24/24-04.gif",
          name: "24-04",
        },
        {
          href: "/static/img/tool/download/role24/24-05.gif",
          name: "24-05",
        },
        {
          href: "/static/img/tool/download/role24/24-06.gif",
          name: "24-06",
        },
        {
          href: "/static/img/tool/download/role24/24-07.gif",
          name: "24-07",
        },
        {
          href: "/static/img/tool/download/role24/24-08.gif",
          name: "24-08",
        },
      ],
    },
  },
  {
    code: "page25",
    image: `/static/img/tool/headerImage/page25/${locale}.png`,
    title: "special.p67",
    subtitle: "2023.12.05",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p68",
      href: "/static/img/tool/zip/Inukai_Hina.zip",
      downloadName: "Inukai_Hina",
      gifFile: [
        {
          href: "/static/img/tool/download/role25/25-01.gif",
          name: "25-01",
        },
        {
          href: "/static/img/tool/download/role25/25-02.gif",
          name: "25-02",
        },
        {
          href: "/static/img/tool/download/role25/25-03.gif",
          name: "25-03",
        },
        {
          href: "/static/img/tool/download/role25/25-04.gif",
          name: "25-04",
        },
        {
          href: "/static/img/tool/download/role25/25-05.gif",
          name: "25-05",
        },
        {
          href: "/static/img/tool/download/role25/25-06.gif",
          name: "25-06",
        },
        {
          href: "/static/img/tool/download/role25/25-07.gif",
          name: "25-07",
        },
        {
          href: "/static/img/tool/download/role25/25-08.gif",
          name: "25-08",
        },
      ],
    },
  },
  {
    code: "page26",
    image: `/static/img/tool/headerImage/page26/${locale}.png`,
    title: "special.p69",
    subtitle: "2023.12.05",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.male,
    middlePage: {
      roleName: "special.p70",
      href: "/static/img/tool/zip/Tachibana_Kaoru.zip",
      downloadName: "Tachibana_Kaoru",
      gifFile: [
        {
          href: "/static/img/tool/download/role26/26-01.gif",
          name: "26-01",
        },
        {
          href: "/static/img/tool/download/role26/26-02.gif",
          name: "26-02",
        },
        {
          href: "/static/img/tool/download/role26/26-03.gif",
          name: "26-03",
        },
        {
          href: "/static/img/tool/download/role26/26-04.gif",
          name: "26-04",
        },
        {
          href: "/static/img/tool/download/role26/26-05.gif",
          name: "26-05",
        },
        {
          href: "/static/img/tool/download/role26/26-06.gif",
          name: "26-06",
        },
        {
          href: "/static/img/tool/download/role26/26-07.gif",
          name: "26-07",
        },
        {
          href: "/static/img/tool/download/role26/26-08.gif",
          name: "26-08",
        },
      ],
    },
  },
  {
    code: "page27",
    image: `/static/img/tool/headerImage/page27/${locale}.png`,
    title: "special.p71",
    subtitle: "2024.01.11",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p72",
      href: "/static/img/tool/zip/Yuzo.zip",
      downloadName: "Yuzo",
      gifFile: [
        {
          href: "/static/img/tool/download/role27/27-01.gif",
          name: "27-01",
        },
        {
          href: "/static/img/tool/download/role27/27-02.gif",
          name: "27-02",
        },
        {
          href: "/static/img/tool/download/role27/27-03.gif",
          name: "27-03",
        },
        {
          href: "/static/img/tool/download/role27/27-04.gif",
          name: "27-04",
        },
        {
          href: "/static/img/tool/download/role27/27-05.gif",
          name: "27-05",
        },
        {
          href: "/static/img/tool/download/role27/27-06.gif",
          name: "27-06",
        },
        {
          href: "/static/img/tool/download/role27/27-07.gif",
          name: "27-07",
        },
        {
          href: "/static/img/tool/download/role27/27-08.gif",
          name: "27-08",
        },
        {
          href: "/static/img/tool/download/role27/27-09.gif",
          name: "27-09",
        },
        {
          href: "/static/img/tool/download/role27/27-10.gif",
          name: "27-10",
        },
      ],
    },
  },
  /*   {
      code: 'page28',
      image: `/static/img/tool/headerImage/page28/${locale}.png`,
      title: 'special.p73',
      subtitle: '2024.02.28',
      topTag: 'special.p2',
      pageName: 'SpecialPage28',
      middlePage: {
          href: '/static/img/tool/zip/Collab_Stamp.zip',
          downloadName: 'Collab_Stamp',
          gifFile1: [
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/01.gif',
                  name: 'Shirakawa_Hotaru-01',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/02.gif',
                  name: 'Shirakawa_Hotaru-02',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/03.gif',
                  name: 'Shirakawa_Hotaru-03',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/04.gif',
                  name: 'Shirakawa_Hotaru-04',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/05.gif',
                  name: 'Shirakawa_Hotaru-05',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/06.gif',
                  name: 'Shirakawa_Hotaru-06',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/07.gif',
                  name: 'Shirakawa_Hotaru-07',
              },
              {
                  href: '/static/img/tool/download/page28/Shirakawa_Hotaru/08.gif',
                  name: 'Shirakawa_Hotaru-08',
              },
          ],
          gifFile2: [
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/01.gif',
                  name: 'Misasagi_Inori-01',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/02.gif',
                  name: 'Misasagi_Inori-02',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/03.gif',
                  name: 'Misasagi_Inori-03',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/04.gif',
                  name: 'Misasagi_Inori-04',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/05.gif',
                  name: 'Misasagi_Inori-05',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/06.gif',
                  name: 'Misasagi_Inori-06',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/07.gif',
                  name: 'Misasagi_Inori-07',
              },
              {
                  href: '/static/img/tool/download/page28/Misasagi_Inori/08.gif',
                  name: 'Misasagi_Inori-08',
              },
          ],
          gifFile3: [
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/01.gif',
                  name: 'Kagamigawa_Chloe-01',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/02.gif',
                  name: 'Kagamigawa_Chloe-02',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/03.gif',
                  name: 'Kagamigawa_Chloe-03',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/04.gif',
                  name: 'Kagamigawa_Chloe-04',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/05.gif',
                  name: 'Kagamigawa_Chloe-05',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/06.gif',
                  name: 'Kagamigawa_Chloe-06',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/07.gif',
                  name: 'Kagamigawa_Chloe-07',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Chloe/08.gif',
                  name: 'Kagamigawa_Chloe-08',
              },
          ],
          gifFile4: [
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/01.gif',
                  name: 'Kagamigawa_Noelle-01',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/02.gif',
                  name: 'Kagamigawa_Noelle-02',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/03.gif',
                  name: 'Kagamigawa_Noelle-03',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/04.gif',
                  name: 'Kagamigawa_Noelle-04',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/05.gif',
                  name: 'Kagamigawa_Noelle-05',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/06.gif',
                  name: 'Kagamigawa_Noelle-06',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/07.gif',
                  name: 'Kagamigawa_Noelle-07',
              },
              {
                  href: '/static/img/tool/download/page28/Kagamigawa_Noelle/08.gif',
                  name: 'Kagamigawa_Noelle-08',
              },
          ],
      }
    },
    {
      code: 'page29',
      image: `/static/img/tool/headerImage/page28/${locale}.png`,
      title: 'special.p75',
      subtitle: '2024.02.28',
      topTag: 'special.p2',
      pageName: 'SpecialPage29',
      middlePage: {
          href: '/static/img/tool/zip/Collab_Icon.zip',
          downloadName: 'Collab_Icon',
          gifFile1: [
              {
                  href: '/static/img/tool/download/page29/Shirakawa_Hotaru/01.png',
                  name: 'Shirakawa_Hotaru-01',
              },
              {
                  href: '/static/img/tool/download/page29/Shirakawa_Hotaru/02.png',
                  name: 'Shirakawa_Hotaru-02',
              },
              {
                  href: '/static/img/tool/download/page29/Shirakawa_Hotaru/03.png',
                  name: 'Shirakawa_Hotaru-03',
              },
          ],
          gifFile2: [
              {
                  href: '/static/img/tool/download/page29/Misasagi_Inori/01.png',
                  name: 'Misasagi_Inori-01',
              },
              {
                  href: '/static/img/tool/download/page29/Misasagi_Inori/02.png',
                  name: 'Misasagi_Inori-02',
              },
              {
                  href: '/static/img/tool/download/page29/Misasagi_Inori/03.png',
                  name: 'Misasagi_Inori-03',
              },
          ],
          gifFile3: [
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Chloe/01.png',
                  name: 'Kagamigawa_Chloe-01',
              },
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Chloe/02.png',
                  name: 'Kagamigawa_Chloe-02',
              },
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Chloe/03.png',
                  name: 'Kagamigawa_Chloe-03',
              },
          ],
          gifFile4: [
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Noelle/01.png',
                  name: 'Kagamigawa_Noelle-01',
              },
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Noelle/02.png',
                  name: 'Kagamigawa_Noelle-02',
              },
              {
                  href: '/static/img/tool/download/page29/Kagamigawa_Noelle/03.png',
                  name: 'Kagamigawa_Noelle-03',
              },
          ],
      }
    }, */
  {
    code: "page30",
    image: `/static/img/tool/headerImage/page30/${locale}.png`,
    title: "special.p77",
    subtitle: "2024.04.11",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p78",
      href: "/static/img/tool/zip/Moegihara_Aoi.zip",
      downloadName: "Moegihara_Aoi",
      gifFile: [
        {
          href: "/static/img/tool/download/page30/30-01.gif",
          name: "30-01",
        },
        {
          href: "/static/img/tool/download/page30/30-02.gif",
          name: "30-02",
        },
        {
          href: "/static/img/tool/download/page30/30-03.gif",
          name: "30-03",
        },
        {
          href: "/static/img/tool/download/page30/30-04.gif",
          name: "30-04",
        },
        {
          href: "/static/img/tool/download/page30/30-05.gif",
          name: "30-05",
        },
        {
          href: "/static/img/tool/download/page30/30-06.gif",
          name: "30-06",
        },
        {
          href: "/static/img/tool/download/page30/30-07.gif",
          name: "30-07",
        },
        {
          href: "/static/img/tool/download/page30/30-08.gif",
          name: "30-08",
        },
      ],
    },
  },
  {
    code: "page31",
    image: `/static/img/tool/headerImage/page31/${locale}.png`,
    title: "special.p79",
    subtitle: "2024.04.11",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p80",
      href: "/static/img/tool/zip/Kazama_Shizuku.zip",
      downloadName: "Kazama_Shizuku",
      gifFile: [
        {
          href: "/static/img/tool/download/page31/31-01.gif",
          name: "31-01",
        },
        {
          href: "/static/img/tool/download/page31/31-02.gif",
          name: "31-02",
        },
        {
          href: "/static/img/tool/download/page31/31-03.gif",
          name: "31-03",
        },
        {
          href: "/static/img/tool/download/page31/31-04.gif",
          name: "31-04",
        },
        {
          href: "/static/img/tool/download/page31/31-05.gif",
          name: "31-05",
        },
        {
          href: "/static/img/tool/download/page31/31-06.gif",
          name: "31-06",
        },
        {
          href: "/static/img/tool/download/page31/31-07.gif",
          name: "31-07",
        },
        {
          href: "/static/img/tool/download/page31/31-08.gif",
          name: "31-08",
        },
      ],
    },
  },
  {
    code: "page32",
    image: `/static/img/tool/headerImage/page32/${locale}.png`,
    title: "special.p81",
    subtitle: "2024.05.29",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p82",
      href: "/static/img/tool/zip/Elsie.zip",
      downloadName: "Elsie",
      gifFile: [
        {
          href: "/static/img/tool/download/page32/32-01.gif",
          name: "32-01",
        },
        {
          href: "/static/img/tool/download/page32/32-02.gif",
          name: "32-02",
        },
        {
          href: "/static/img/tool/download/page32/32-03.gif",
          name: "32-03",
        },
        {
          href: "/static/img/tool/download/page32/32-04.gif",
          name: "32-04",
        },
        {
          href: "/static/img/tool/download/page32/32-05.gif",
          name: "32-05",
        },
        {
          href: "/static/img/tool/download/page32/32-06.gif",
          name: "32-06",
        },
        {
          href: "/static/img/tool/download/page32/32-07.gif",
          name: "32-07",
        },
        {
          href: "/static/img/tool/download/page32/32-08.gif",
          name: "32-08",
        },
      ],
    },
  },
  /*  {
    code: "page33",
    image: `/static/img/tool/headerImage/page33/${locale}.png`,
    title: "special.p83",
    subtitle: "2024.06.19",
    topTag: "special.p2",
    pageName: "SpecialPage33",
    middlePage: {
      href: "/static/img/tool/zip/RiichiCity_Danganronpa_Collab_Stamp.zip",
      downloadName: "RiichiCity_Danganronpa_Collab_Stamp",
      gifFile: [
        {
          href: "/static/img/tool/download/page33/1Naegi01.gif",
          name: "1Naegi01",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi02.gif",
          name: "1Naegi02",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi03.gif",
          name: "1Naegi03",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi04.gif",
          name: "1Naegi04",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi05.gif",
          name: "1Naegi05",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi06.gif",
          name: "1Naegi06",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi07.gif",
          name: "1Naegi07",
        },
        {
          href: "/static/img/tool/download/page33/1Naegi08.gif",
          name: "1Naegi08",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri01.gif",
          name: "2Kirigiri01",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri02.gif",
          name: "2Kirigiri02",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri03.gif",
          name: "2Kirigiri03",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri04.gif",
          name: "2Kirigiri04",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri05.gif",
          name: "2Kirigiri05",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri06.gif",
          name: "2Kirigiri06",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri07.gif",
          name: "2Kirigiri07",
        },
        {
          href: "/static/img/tool/download/page33/2Kirigiri08.gif",
          name: "2Kirigiri08",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste01.gif",
          name: "3Celeste01",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste02.gif",
          name: "3Celeste02",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste03.gif",
          name: "3Celeste03",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste04.gif",
          name: "3Celeste04",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste05.gif",
          name: "3Celeste05",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste06.gif",
          name: "3Celeste06",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste07.gif",
          name: "3Celeste07",
        },
        {
          href: "/static/img/tool/download/page33/3Celeste08.gif",
          name: "3Celeste08",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima01.gif",
          name: "4Enoshima01",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima02.gif",
          name: "4Enoshima02",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima03.gif",
          name: "4Enoshima03",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima04.gif",
          name: "4Enoshima04",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima05.gif",
          name: "4Enoshima05",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima06.gif",
          name: "4Enoshima06",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima07.gif",
          name: "4Enoshima07",
        },
        {
          href: "/static/img/tool/download/page33/4Enoshima08.gif",
          name: "4Enoshima08",
        },
      ],
    },
  }, */

  {
    code: "page34",
    image: `/static/img/tool/headerImage/page34/${locale}.png`,
    title: "special.p85",
    subtitle: "2024.08.01",
    topTag: "special.p2",
    pageName: "SpecialPage",
    gender: gender.female,
    middlePage: {
      roleName: "special.p86",
      href: "/static/img/tool/zip/Kisaragi_Lisa.zip",
      downloadName: "Kisaragi_Lisa",
      gifFile: [
        {
          href: "/static/img/tool/download/page34/34-01.gif",
          name: "34-01",
        },
        {
          href: "/static/img/tool/download/page34/34-02.gif",
          name: "34-02",
        },
        {
          href: "/static/img/tool/download/page34/34-03.gif",
          name: "34-03",
        },
        {
          href: "/static/img/tool/download/page34/34-04.gif",
          name: "34-04",
        },
        {
          href: "/static/img/tool/download/page34/34-05.gif",
          name: "34-05",
        },
        {
          href: "/static/img/tool/download/page34/34-06.gif",
          name: "34-06",
        },
        {
          href: "/static/img/tool/download/page34/34-07.gif",
          name: "34-07",
        },
        {
          href: "/static/img/tool/download/page34/34-08.gif",
          name: "34-08",
        },
      ],
    },
  },
];

// replace speciall.p22 to cv text in english lang
export const cvText = {
  page01: "(CV: Arai Satomi)",
  page02: "(CV: Arai Satomi)",
  page03: "(CV: Ogura Yui)",
  page04: "(CV: Hasegawa Reina)",
  page05: "(CV: Hasegawa Reina)",
  page06: "(CV: Arai Satomi)",
  page07: "(CV: Morishima Shuuta)",
  page08: "(CV: Morishima Shuuta)",
  page09: "(CV: Ishikawa Yui)",
  page10: "(CV: Ishikawa Yui)",
  page13: "(CV: Suguta Hina)",
  page14: "(CV: Namikawa Daisuke)",
  page15: "(CV: Numakura Manami)",
  page16: "(CV: Yuuki Aoi)",
  page17: "(CV: Onishi Saori)",
  page18: "(CV: Uchida Maaya)",
  page19: "(CV: Sugiyama Noriaki)",
  page20: "(CV: Yukana)",
  page21: "(CV: Yukana)",
  page22: "(CV: Yuuki Aoi)",
  page23: "(CV: Suguta Hina)",
  page24: "(CV: Natsuyoshi Yūko)",
  page25: "(CV: Natsuyoshi Yūko)",
  page26: "(CV: Uemura Yūto)",
  page27: "(CV: Tamura Yukari)",
  page30: "(CV: Naganawa Maria)",
  page31: "(CV: Naganawa Maria)",
  page32: "(CV: Amamiya Sora)",
  page34: "(CV: Amamiya Sora)",
};

export const specialDownloadList = (locale) => [
  {
    href: "https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Voices‗30.zip",
    image: `/static/img/tool/downloadButton/voice_${locale}.png?v=20240111`,
  },
  {
    href: "https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Stamps‗30.zip",
    image: `/static/img/tool/downloadButton/emote_${locale}.png?v=20240111`,
  },
  {
    href: "https://d3qgi0t347dz44.cloudfront.net/release/windowspack/Wallpapers.zip",
    image: `/static/img/tool/downloadButton/wallpaper_${locale}.png?v=20221028`,
  },
];
